
import { Company } from '@/models/dto/Company'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PublicTermsBody extends Vue {
  @Prop({ required: true }) company!: Company
  @Prop({ required: true }) terms!: string

  get title(): string {
    return `${this.company.name} - Terms & Conditions`
  }

  get sm(): boolean {
    return this.$vuetify.breakpoint.width < 632
  }
  get md(): boolean {
    return (
      this.$vuetify.breakpoint.width >= 632 &&
      this.$vuetify.breakpoint.width < 1022
    )
  }

  get style(): Record<string, string> {
    let style = {}

    if (!this.sm) {
      style = {
        ...style,
        boxShadow: '0px 6px 25px rgba(0, 0, 0, 0.03)',
        maxHeight: 'calc(100vh - 100px - 205px)',
      }
    }

    if (this.md) {
      style = { ...style, maxHeight: 'calc(100vh - 100px - 245px)' }
    }

    return style
  }
}
