
import { Company } from '@/models/dto/Company'
import { staticResource } from '@/utils/env'
import { httpHelper, phoneFormatFilter } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'
import colors from '@/scss/_colors-export.scss'

@Component({})
export default class PublicTermsAppBar extends Vue {
  @Prop({ required: true }) title: string
  @Prop({ required: true }) company: Company

  phoneFormatFilter = phoneFormatFilter
  staticResource = staticResource

  get color(): string {
    return this.company.primaryColor || colors['black']
  }

  get hasWebsiteUrl(): boolean {
    return !!this.company.websiteUrl
  }

  openWebsiteLink(): void {
    if (this.hasWebsiteUrl) {
      window.open(httpHelper(this.company.websiteUrl), '_blank')
    }
  }
}
