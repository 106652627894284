import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { AxiosResponse } from 'axios'
import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { Terms, TermsPageResponse } from '@/models/dto/Terms'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Terms>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `&page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    const url = `https://${apiBaseUrl()}/company-terms?${query}`
    return httpService.get(url)
  },
  create(terms: Terms): Promise<AxiosResponse<Terms>> {
    const url = `https://${apiBaseUrl()}/companyTerms`
    return httpService.post(url, terms)
  },
  delete(id: number): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/companyTerms/${id}`
    return httpService.delete(url)
  },
  partialUpdate(
    terms: Partial<Terms & { companyTermsId: number }>
  ): Promise<AxiosResponse<Terms>> {
    const payload = { ...terms, companyTermId: terms.companyTermsId }
    const url = `https://${apiBaseUrl()}/companyTerms`
    return httpService.patch(url, payload)
  },
  byHash(hash: string): Promise<AxiosResponse<TermsPageResponse>> {
    const url = `https://${apiBaseUrl()}/companyTerms/hash/${hash}`
    return httpService.get(url)
  },
}
