
import { Vue, Component } from 'vue-property-decorator'
import app from '@/store/modules/app'
import { Company } from '@/models/dto/Company'
import termsClient from '@/services/terms'
import PublicAppBar from '@/components/PublicAppBar.vue'
import { Terms } from '@/models/dto/Terms'
import Footer from '@/components/CheckoutFooter.vue'
import Loader from '@/components/CheckoutLoader.vue'
import PublicTermsBody from '@/components/PublicTermsBody.vue'
import { useFavicon } from '@vueuse/core'
import { isDevEnvironment, staticResource } from '@/utils/env'

@Component({
  components: { PublicAppBar, Footer, Loader, PublicTermsBody },
})
export default class PublicTerms extends Vue {
  company: Company = null
  terms: Terms = null

  get sm(): boolean {
    return this.$vuetify.breakpoint.width < 632
  }

  async mounted(): Promise<void> {
    if (!isDevEnvironment() && !app.isCustomersEnvironment) {
      this.$router.push({ name: 'home' })
      return
    }

    if (!this.$route.params.hash) {
      this.$router.push({ name: 'not-found' })
      return
    }

    try {
      const {
        data: { company, terms },
      } = await termsClient.byHash(this.$route.params.hash)
      this.company = company
      this.terms = terms

      window.document.title = `${company.name} - Terms & Conditions`
      let faviconUrl = this.company.faviconUrl || this.company.darkLogoUrl
      if (faviconUrl) {
        const icon = useFavicon()
        faviconUrl = staticResource(faviconUrl)
        icon.value = faviconUrl
      }
    } catch (e) {
      console.error(e)
      this.$router.push({ name: 'not-found' })
    }
  }
}
