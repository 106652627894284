var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full margin-x-auto padding-x-5 z-7",style:({ maxWidth: '1220px' })},[_c('div',{staticClass:"w-full h-80 position-absolute top-0 left-0 background-white",style:({ boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.15)' })}),_c('div',{staticClass:"h-80 d-flex justify-space-between align-center flex-shrink-0"},[_c('div',{staticClass:"z-7",class:{ 'cursor-pointer z-1': _vm.hasWebsiteUrl },on:{"click":_vm.openWebsiteLink}},[_c('img',{class:{
          'max-h-52 max-w-200': _vm.$vuetify.breakpoint.width >= 650,
          'max-h-36 max-w-120': _vm.$vuetify.breakpoint.width < 650,
        },attrs:{"src":_vm.staticResource(_vm.company.darkLogoUrl)}})]),_c('div',{staticClass:"text-center z-2",class:{
        'd-flex align-center position-absolute top-0 h-80 left-one-half':
          _vm.$vuetify.breakpoint.width >= 650,
        'font-18 margin-t-1': _vm.$vuetify.breakpoint.width < 650,
      },style:(_vm.$vuetify.breakpoint.width >= 650
          ? { transform: 'translateX(-50%)' }
          : null)},[_c('h2',{staticClass:"font-bold",class:{ 'font-18 margin-t-1': _vm.$vuetify.breakpoint.width < 650 }},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),(_vm.$vuetify.breakpoint.width >= 650)?_c('CUButton',{staticClass:"text-white",attrs:{"color":_vm.color,"small":"","unset-width":"","href":`tel:${_vm.company.phone}`}},[_c('CUIcon',{staticClass:"margin-r-1",attrs:{"width":"17px","height":"17px"}},[_vm._v("phone")]),_vm._v(" "+_vm._s(_vm.phoneFormatFilter(_vm.company.phone))+" ")],1):_c('CUButton',{staticClass:"text-white rounded-circle",attrs:{"color":_vm.color,"height":"36","width":"36","href":`tel:${_vm.company.phone}`}},[_c('CUIcon',{attrs:{"width":"17px","height":"17px"}},[_vm._v("phone")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }